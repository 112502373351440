import axios from 'axios'
import api from './api'
import { Toast } from 'vant'

let baseURL = process.env.VUE_APP_BASE_API

// 通过url判断是否是某个接口
let contains = (arr, url) => {
  let i = arr.length;
  while (i--) {
      if (url.indexOf(arr[i]) !== -1) {
          return true
      }
  }
  return false
}

// 不需要提示错误的接口
let noNoticeApi = []

const instance = axios.create({
  baseURL: baseURL,
  headers: { 'Content-Type': 'application/json' },
})

instance.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(response => {

  let res = response.data

  if (res.code == 4001) {
      return
  }

  let notNotice = contains(noNoticeApi, response.config.url)
  if (notNotice) {
      return
  }
  if (res.status) {
      //todo
  } else {
    Toast(res.message)
  }

  return response
}, error => {
  Toast(error)
  return Promise.reject(error)
})

export default api(instance)