import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    meta: { title: "Kar Studio", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/home/index.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: { title: "About", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/home/about.vue"),
  },
  {
    path: "/process",
    name: "process",
    meta: { title: "Process", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "process" */ "@/views/home/process.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: { title: "Contact", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/home/contact.vue"),
  },
  {
    path: "/products",
    name: "products",
    meta: { title: "Products", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "products" */ "@/views/home/products.vue"),
    children: [
      {
        path: "furniture",
        name: "furniture",
        meta: { title: "Furniture", need_auth: false },
        component: () =>
          import(
            /* webpackChunkName: "products" */ "@/views/home/furniture.vue"
          ),
      },
      {
        path: "clothing",
        name: "clothing",
        meta: { title: "Clothing", need_auth: false },
        component: () =>
          import(
            /* webpackChunkName: "products" */ "@/views/home/clothing.vue"
          ),
      },
      {
        path: "cooperation",
        name: "cooperation",
        meta: { title: "Cooperation", need_auth: false },
        component: () =>
          import(
            /* webpackChunkName: "products" */ "@/views/home/cooperation.vue"
          ),
      },
    ],
  },
  {
    path: "/project",
    name: "project",
    meta: { title: "Project", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "project" */ "@/views/home/project.vue"),
  },
  {
    path: "/catalog",
    name: "catalog",
    meta: { title: "Catalog", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "catalog" */ "@/views/home/catalog.vue"),
  },
  {
    path: "/collaboration",
    name: "collaboration",
    meta: { title: "collaboration", need_auth: false },
    component: () =>
      import(
        /* webpackChunkName: "collaboration" */ "@/views/home/cooperation.vue"
      ),
  },
  {
    path: "/interior",
    name: "interior",
    meta: { title: "Interior", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "interior" */ "@/views/home/interior.vue"),
  },
  {
    path: "/article",
    name: "article",
    meta: { title: "Kar Studio", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "article" */ "@/views/home/article.vue"),
  },
  {
    path: "/goods",
    name: "goods",
    meta: { title: "Kar Studio", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "goods" */ "@/views/home/goods.vue"),
  },
  {
    path: "/search",
    name: "search",
    meta: { title: "Kar Studio", need_auth: false },
    component: () =>
      import(/* webpackChunkName: "search" */ "@/views/home/search.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
