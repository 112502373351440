import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index'
import api from '@/api'

import Vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/reset.scss'

import { Button, Popup, DropdownMenu, DropdownItem, List, Search } from 'vant';

const app = createApp(App)
app.use(router)
app.use(Vant)
app.use(Button)
app.use(Popup)
app.use(DropdownMenu)
app.use(DropdownItem)
app.use(List)
app.use(Search)
app.mount('#app')
app.config.globalProperties.$api = api